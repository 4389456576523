import _ from 'lodash';
import moment from "moment";
import randomBytes from 'randombytes'

import { DEFAULT_DIFF, MAP_LEVEL } from './constant';

const stringDateToDate = (date) => {
	const v = _.toString(date).trim();

	let item = null;
	if (_.isEmpty(v) || _.isEqual(v, "Invalid date")) {
		// console.log({ v }, item);
		return null;
	}

	item = moment(v, moment.ISO_8601, true);
	if (item.isValid()) {
		// console.log({ v }, item.toDate());
		return item.toDate();
	}

	item = moment(v, 'DD MMMM YYYY');
	if (item.isValid()) {
		// console.log({ v }, item.toDate());
		return item.toDate();
	}

	item = moment(v, 'DD/MM/YYYY');
	if (item.isValid()) {
		// console.log({ v }, item.toDate());
		return item.toDate();
	}
	item = moment(v, 'DD-MM-YYYY');
	if (item.isValid()) {
		// console.log({ v }, item.toDate());
		return item.toDate();
	}

	return null;
};

const buildOptions = (array, opts = {}) => {
	const force = _.get(opts, 'force', true);
	const temp = Date.now();
	return _.sortBy(array).map(str => {
		const uniq = _.chain(str).snakeCase().toUpper().value();
		return ({
			key: `${temp}-${uniq}`,
			text: str,
			value: force ? uniq : str,
		});
	});
}

const ab2str = (buf) => {
	return String.fromCharCode.apply(null, new Uint8Array(buf));
}

export const saveFile = (resp, filename) => {
	try {
		const { data } = resp;
		const blob = new Blob([data]);
		if (resp.status === 200 && (blob instanceof Blob)) {
		 	saveFileFromBuffer(blob, filename);
		} else {
			const obj = JSON.parse(ab2str(data));
			console.log(obj);
		}
	} catch (error) {
		console.log({ error });
	}
}

export const saveFileFromBuffer = (buffer, filename) => {
	const url = window.URL.createObjectURL(buffer);
	const link = document.createElement('a');
	document.body.appendChild(link);
	link.href = url;
	link.setAttribute('download', filename);
	link.click();
}

const buildStatusNilai = (_nilai, _kkm) => {
	const kkm 	= Number(_kkm);
	const nilai = Number(_nilai);
	
	if (nilai >= kkm) { return ''; }
	if (nilai >= (kkm - (1 * DEFAULT_DIFF))) { return "yellow"; }
	if (nilai >= (kkm - (2 * DEFAULT_DIFF))) { return "brown"; }
	return "red";
}

const buildWeight = (penilaian, nomor) => {
	let f = 0;
	switch(penilaian.toUpperCase()) {
		case "HARIAN": { f = 1; break}
		case "UTS": { f = 2; break}
		case "UAS": { f = 3; break}
		default: 
	}
	return _.compact([
		f.toString(),
		_.isEmpty(nomor) ? null : (nomor + '').split('.').map(v => v.toString().padStart(3, '*')).join(''),
	]).join('');
}

const randomPassword = () => {
	return randomBytes(4).toString('hex');
}

const validateEmail = email =>{
	let regex =/^\w+[+.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i
	return regex.test(email)
}

const predikatToAngka = (predikat = "") => {
	switch((predikat || '').toString().toUpperCase()) {
		case "A": { return 4; }
		case "B": { return 3; }
		case "C": { return 2; }
		case "D": { return 1; }
		default : { return 0; }
	}
}

const angkaToPredikat = (angka = 0) => {
	switch(Number(angka)) {
		case 4: { return "A"; }
		case 3: { return "B"; }
		case 2: { return "C"; }
		case 1: { return "D"; }
		default : { return "E"; }
	}
}

const validateUsername = username =>{
	let regex = /^\S{4,}$/.test(username)
	return regex
}

const validateNoHp = number =>{
	let regex = /^[0-9]+$/.test(number)

	if(_.isEmpty(number)){return false}
	if(_.isNaN(Number(number))){return false}
	if(number.length < 9){return false}

	return regex
}

const validateNoTelponRumah = number =>{
	let regex = /^[0-9]+$/.test(number)

	if(_.isEmpty(number)){return false}
	if(_.isNaN(Number(number))){return false}
	if(number.length < 6){return false}

	return regex
}

const getTahunAjaranPPDB = (date = moment()) =>{
	const y = Number(date.year());
	const m = Number(date.month());

	const ta = [];
	if (m >= 6) {
		// Juli or more
		ta.push(y + 1, y + 2);
	} else {
		ta.push(y, y + 1);
	}
	return ta.join('/');
}

const validateNIS = username =>{
	let regex = /^\S{5,}$/.test(username)
	return regex
}

const getAccessProps = (level = "") => {
	const access = {};
	const split = (level || "").toString().toUpperCase().split("");
	split.map(v => _.set(access, _.get(MAP_LEVEL, v, "-"), true));
	return _.omit(access, "-");
}

const buildFullSemester = (tingkat, semester) => {
	let smt = Number(semester);
	smt = (smt % 2) === 1 ? 1 : 2; 
	switch (tingkat.toUpperCase()){
		case "XI" : { return 2 + smt; }
		case "XII" : { return 4 + smt; }
		case "VIII" : { return 2 + smt; }
		case "IX" : { return 4 + smt; }
		default: return smt
	}
}

const swapItem = (l, s, e) => {
	const list = [...l];
	const item = list[s];
	list.splice(s, 1);
	list.splice(e, 0, item);
	return list;
}

const parseNumber = (number) => {
	let val = ((number || "").toString()).replace(/[\D\s._-]+/g, "");
	val = val ? parseInt(val, 10) : 0;
	return val;
}
const toCurrency = (number) => {
	return (parseNumber(number)).toLocaleString("id-ID");
}

export { 
 	ab2str, toCurrency, parseNumber,
	buildOptions, buildStatusNilai, buildWeight,
	randomPassword, buildFullSemester,
	predikatToAngka, angkaToPredikat,
	validateEmail, validateUsername, validateNIS, validateNoHp,validateNoTelponRumah,
	getAccessProps, swapItem, getTahunAjaranPPDB, stringDateToDate
};